<template>
  <div class="wameed-dashboard-page-content mb-5">
    <section class="wameed-dashboard-page-content_body">
      <b-row class="no-gutters px-2">
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              setting-card
              align-items-center
              justify-content-center
              pb-1 "
            @click="jobs"
          >
            <h5 class="mb-0 text-medium-30 text-font-main mb-3 mx-2">
              {{ $t("jobs") }}
            </h5>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {
    jobs() {
      this.$router.push({ name: "jobs", params: { lang: this.$i18n.locale } });
    },
  },
};
</script>
 
